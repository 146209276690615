import { debug } from '../debug.js';

// Angepasstes Logging-
function kashlog(message) {

    if (debug) {
        console.log('[KASH] ' + message)
    }

}

// In Vite sind die Module standardmäßig voneinander isoliert, wenn sie importiert werden.
// Das bedeutet, dass Funktionen, die in einem Modul definiert sind (wie in kash.js),
// nicht automatisch im globalen Scope verfügbar sind und daher nicht in anderen Skripten
// sichtbar sind, die im gleichen Dokument eingebettet sind, es sei denn, sie wurden
// explizit dem globalen Objekt (zum Beispiel window) hinzugefügt.
window.kashlog = kashlog

// Dezimalformatierung
// Scheint aktuell nicht im Einsatz zu sein.
// 2023-11-06 testweise auskommentiert.
/*
function number_format(n, s, d, t) {
    n = n.toFixed(s);
    z = n.split('.');
    g = z[0].split('').reverse();
    g = g.join('');
    s = '';
    for (var i = 1; i <= g.length; i++) {
        s += g.charAt(i - 1);
        if (i % 3 == 0 && i != g.length) s += t;
    }
    g = s.split('').reverse();
    de = '';
    if (z[1]) de = d + z[1];
    g = g.join('') + de;
    return g;
}
*/

// Datepicker-Initialisierung
$(document).ready(function () {
    $.ajaxSetup({ cache: false });
    $.fn.datepicker.defaults.language = "de";
    $.fn.datepicker.defaults.autoclose = true;
    $.fn.datepicker.defaults.calendarWeeks = true;
    $.fn.datepicker.defaults.enableOnReadonly = true;
    $.fn.datepicker.defaults.orientation = 'auto bottom';
    $('[data-toggle="tooltip"]').tooltip();
});

// Um Benutzereinstellungen bezüglich der Sichtbarkeit von verschiedenen Abschnitten
// ohne Backend-Logik oder Server-Seitenspeicherung zu behalten, indem es die Zustände
// der Benutzeroberfläche konsistent hält, auch wenn der Nutzer die Seite aktualisiert
// oder erneut besucht.
// Unklar, ob aktuell benötigt.
$('.collapse').on('hidden.bs.collapse', function () {
    localStorage.removeItem('open_' + this.id);
});

$('.collapse').on('shown.bs.collapse', function () {
    localStorage.setItem('open_' + this.id, true);
});

$('.collapse').each(function () {
    // Default close unless saved as open
    if (localStorage.getItem('open_' + this.id) == "true") {
        $(this).collapse('show');
    }
});

// Unklare Aufgabe ... da KASH nicht vollständig responsiv angelegt ist und
// es auch keine id page-wrapper mehr gibt ... ?
// 2023-11-06: Testweise auskommentiert.
/*
const calcSideBarHeight = function () {
    // Loads the correct sidebar on window load,
    // collapses the sidebar on window resize.
    // Sets the min-height of #page-wrapper to window size
    topOffset = 54;
    width = (this.window.innerWidth > 0) ? this.window.innerWidth : this.screen.width;
    if (width < 768) {
        $('div.navbar-collapse').addClass('collapse');
        topOffset = 104; // 2-row-menu
    } else {
        $('div.navbar-collapse').removeClass('collapse');
    }
    sideBarHeight = $('#sidebar').innerHeight();
    height = ((this.window.innerHeight > 0) ? this.window.innerHeight : this.screen.height) - 1;
    //height = height - topOffset;
    height = (sideBarHeight > height ? sideBarHeight + 35 : height);
    if (height < 1) height = 1;
    if (height > topOffset) {
        $("#page-wrapper").css("min-height", (height) + "px");
    }
};
*/

$('.metisMenu').metisMenu().on('shown.metisMenu', function () {
    calcSideBarHeight();
}).on('hidden.metisMenu', function () {
    calcSideBarHeight();
});

// Transition für Success-Meldungen.
$('div.alert.alert-success').delay(3000).fadeOut(350);

// Aktualisierung der Zeit im KASH-Header alle 15 Sekunden.
// Disfunct, da ich aktuell nur beim Laden der Seite in master.blade.php aktualisiere.
// Könnte man wieder umbauen ...
/*
let zeitAktualisieren = function () {
    hours = aktZeit.getUTCHours();
    minute = aktZeit.getUTCMinutes();
    $('#time').html((hours < 10 ? '0' : '') + hours + ':' + (minute < 10 ? '0' : '') + minute);
};

let timeout = 15;

setInterval(function () {
    aktZeit.setUTCSeconds(aktZeit.getUTCSeconds() + timeout);
    zeitAktualisieren();
}, timeout * 1000);
*/

// Verwaltet Panel und Fokus in Views mit mehreren Panels.
function managePanelAndFocus(viewId, entityId, entityType, defaultPanel) {

    // Holt das DOM-Element der aktuellen Ansicht.
    const viewElement = document.getElementById(viewId);
    // Liest die aktuelle Entity-ID aus den Datenattributen des Elements.
    const currentEntityId = viewElement?.dataset[entityId];
    // Liest das Datenattribut für das Error Panel, falls vorhanden.
    const errorPanel = viewElement?.dataset.errorPanel;
    // Liest das Datenattribut für das Panel, falls vorhanden.
    const panel = viewElement?.dataset.panel;

    // Standard-Tab, wird genutzt, falls keine anderen Bedingungen zutreffen.
    let targetTab = defaultPanel;
    // Liest den letzten Tab-Status und die Entity-ID aus dem Local Storage.
    const lastEntityTab = localStorage.getItem(`last${entityType}Tab`) ?? null;
    const savedEntityId = localStorage.getItem(`last${entityType}Id`) ?? null;

    // Setzt das Ziel-Panel basierend auf der Fehlerbehandlung oder der gespeicherten Session-Information.
    if (errorPanel) {
        targetTab = `#${errorPanel}`;
    } else if (savedEntityId && savedEntityId === currentEntityId) {
        targetTab = panel ? `#${panel}` : lastEntityTab;
    }

    // Loggt den aktuellen Status der Panel-Auswahl für Debugging-Zwecke.
    window.kashlog(`${entityType.toUpperCase()}: targetTab = ${targetTab} / savedId = ${savedEntityId} / Id = ${currentEntityId}`);
    window.kashlog(`ERROR_PANEL = ${errorPanel}`);
    window.kashlog(`PANEL = ${panel}`);
    window.kashlog(`targetTab = ${targetTab}`);

    // Speichert den aktuellen Tab und die Entity-ID im Local Storage.
    localStorage.setItem(`last${entityType}Tab`, targetTab);
    localStorage.setItem(`last${entityType}Id`, currentEntityId);

    // Nach dem Laden der Seite wird das korrekte Panel aktiviert und der Fokus gesetzt.
    $(document).ready(function () {
        setFocus(targetTab);
    });

    // Funktion zum Setzen des Fokus auf das erste sichtbare und erreichbare Eingabefeld im Panel.
    function setFocus(targetTab) {
        // Aktiviert das Tab mit dem angegebenen Ziel.
        $('#navTab a[href="' + targetTab + '"]').tab('show');
        // Setzt den Fokus auf das erste sichtbare und aktive Eingabeelement, das bestimmte Kriterien erfüllt.
        $(targetTab + ' :input:enabled:visible').not('[type="radio"]').not('[data-provide="datepicker"]').not('select').first().focus();

        // Leider funktioniert das beim Artikel-Tab nicht, deshalb hier ein Sonderfall.
        // targetTabs mit ID 'artikel' gibt es in der Document- und StockReceipt-View.
        if (targetTab === '#artikel') {
            let i = 0 // erste Zeile nehmen
            let artikelnummer

            // Prüfen, ob wir uns in der Document- oder StockReceipt-View befinden
            if ($('#document_items\\[' + i + '\\]\\[artikelnummer\\]').length) {
                artikelnummer = $('#document_items\\[' + i + '\\]\\[artikelnummer\\]');
            } else if ($('#stockreceipt_items\\[' + i + '\\]\\[artikelnummer\\]').length) {
                artikelnummer = $('#stockreceipt_items\\[' + i + '\\]\\[artikelnummer\\]');
            }

            // Fokus nur in leere Felder setzen, aber nicht, wenn schon Artikelnummer vorhanden (stört).
            if ($(artikelnummer).val().length === 0) {
                $(artikelnummer).focus();
            }

        }

        // Ausgabe zur Kontrolle der Input-Felder. Unterschiedliche Varianten aus den früheren getrennten Tab/Focus-Manager-Funktionen.

        /*
        //let inputElements = $(targetTab + ' :input:enabled:visible').not('[type="radio"]').not('[data-provide="datepicker"]');
        //window.kashlog("inputElements: " + JSON.stringify(inputElements, null, 2));
        */

        /*
        let inputElements =
            $(targetTab + ' :input:enabled:visible').each(function() {
                //if($this == $this);
                //if($(this).is(':input:enabled:visible'));
            }).first();
        let inputElements = $(targetTab + ' :input:enabled:visible:first');
        //console.log("inputElements: " + JSON.stringify(inputElements, null, 2));
        */

    }

    // Beim Absenden eines Formulars wird der aktuelle Tab gespeichert.
    $("form").submit(function () {
        $('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
            localStorage.setItem(`last${entityType}Tab`, $(e.target).attr('href'));
        });
    });

    // Beim Wechseln des Tabs wird der aktuelle Tab im Local Storage gespeichert und der Fokus entsprechend gesetzt.
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        let currTab = $(e.target).attr('href');
        localStorage.setItem(`last${entityType}Tab`, currTab);
        window.kashlog(`TAB-WECHSEL - last${entityType}Tab = ${currTab}`);
        setFocus($(e.target).attr('href'));
    });
}

window.managePanelAndFocus = managePanelAndFocus

kashlog('kash.js loaded')
