// Basis mit jQuery
import './bootstrap' // importiert axios
import Popper from 'popper.js'; // .tooltip() relies on popper.js
window.Popper = Popper;
import $ from 'jquery';
window.$ = window.jQuery = $;

kashlog('app.js - nach jQuery')

// Livewire + Alpine
// Einbindung von Livewire 3 inklusive Alpine 3, wenn man nicht inkludierte Plugins braucht,
// wie z.B. von usernotnull/tall-toasts.
// Dazu: https://livewire.laravel.com/docs/installation#manually-bundling-livewire-and-alpine
import {Livewire, Alpine} from '../../vendor/livewire/livewire/dist/livewire.esm';
import {livewire_hot_reload} from 'virtual:livewire-hot-reload'
import ToastComponent from '../../vendor/usernotnull/tall-toasts/resources/js/tall-toasts'

// 3rd-Party aus node_modules
import 'bootstrap'
import 'metismenu/dist/metisMenu'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.de.min'
import 'datatables/media/js/jquery.dataTables.min'
import 'datatables-bootstrap3-plugin'
import 'datatables.net-fixedheader'
import 'moment'
import 'jquery-match-height/dist/jquery.matchHeight'
import 'corejs-typeahead'

// 3rd-Party aus resources/js
import './3rd-party/dataTables.defaults'; // DataTables-Lang-Ressource
import './3rd-party/dataTables.sorting.datetime-moment'; // DataTables-Plugin
import './3rd-party/dataTables.sorting.numericComma'; // DataTables-Plugin
import './3rd-party/sb-admin-2' // Start Bootstrap - SB Admin 2 v3.3.7+1 - Legacy version

// Custom JS
import './kash/kash'

kashlog("app.js - nach Imports")

// Alpine und Livewire konfigurieren und starten
Alpine.plugin(ToastComponent)
Alpine.data('ToastComponent', ToastComponent)
window.Alpine = Alpine
Livewire.start()
// Vite-Livewire-Plugin
// .env.VITE_LIVEWIRE_OPT_IN=true blendet Opt-in-Checkbox ein.
// Sollte idealerweise standardmäßig deaktiviert sein, da LW-Komponenten
// nur in Belegen und Reports verwendet werden.
// Noch unklar, ob das vom Autor implementiert wird.
livewire_hot_reload(false);

// jQueryReady senden
$(document).ready(function () {

    // jQuery ist bereit

    // Senden eines Custom-Events, das anzeigt, dass jQuery bereit ist.
    // Wird in den meisten Scripts der einzelnen Views benötigt.

    const event = new Event('jQueryReady');
    window.dispatchEvent(event);

    kashlog("event: jQueryReady")

});

/*
// Versuch, Livewire-Fehlermodals zu unterdrücken, z.B. bei php artisan down
// Scheint aber nicht zu wirken.
window.livewire.onError(statusCode => {
    return false
})
*/
